::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-thumb {
    background: #3A4047;
    border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover{
    background: #2E3339;
}
::-webkit-scrollbar-track{
    background: #343a40;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #343a40;
}
